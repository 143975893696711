import React, { useEffect, useState } from 'react'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { cookies } from 'scripts/cookies'
import EbaseTransfer from './Ebase'
import SamlTransfer from './Saml'
import FormBasedAuthTransfer from './FormBasedAuth'
import { navigate, PageProps } from 'gatsby'
import axios from 'axios'
import { GetAppResponse } from 'types/ApiResponse'
import { fullUrl } from 'scripts/fullUrl'
import { AppType } from 'types/App'
import { ConnectionType } from 'types/Connection'
import { checkAppAuthority } from 'api/checkAppAuthority'
import { ToTransferState } from 'types/NavigateState'
import { Loading } from 'presentation/components/Loading'
import { BookmarkTransfer } from './Bookmark'
import { getTeam } from 'api/getTeam'
import { getSubdomain } from 'scripts/getSubdomainForHost'

/**
 * Transfer コンポーネント
 *
 * 1. サブドメインからチーム情報を取得し、デバイス証明書の可否を判定
 * 2. アプリ情報・Connection情報を取得
 * 3. checkAppAuthority による権限チェックを実施
 * 4. エラー時や未権限時に画面遷移
 * 5. 上記が全て成功すれば実際の遷移（Ebase / SAML / Form Based / Bookmark など）
 */
export const Transfer = (props: PageProps) => {
  // ページ遷移時に渡される state (SAML 専用パラメータなど)
  const navigateState: ToTransferState = props.location.state as ToTransferState

  // セッション用トークン
  const sessionToken = cookies.get('session_token')

  // クエリパラメータ ( app_id, ac_id )
  const [appId] = useQueryParam('app_id', NumberParam)
  const [acId] = useQueryParam('ac_id', StringParam)

  // アプリ情報・接続情報
  const [app, setApp] = useState<Partial<AppType>>()
  const [connection, setConnection] = useState<ConnectionType>()

  // ローディング状態 (初期: true)
  const [loading, setLoading] = useState<boolean>(true)

  // アクセス権があるかどうか
  const [accessAllowed, setAccessAllowed] = useState(false)

  /**
   * アプリ一覧に戻す際の共通関数
   * @param snackbarText 表示するメッセージ
   * @param snackbarAttribute 追加のスナックバー属性
   */
  const navigateToAppsByFail = (
    snackbarText: string,
    snackbarAttribute?: string,
  ) => {
    navigate('/apps', {
      state: {
        snackbarText: snackbarText,
        snackbarAttribute: snackbarAttribute,
      },
      replace: true,
    })
    return
  }

  /**
   * メインとなる非同期処理
   *   1. チーム情報の取得→デバイス証明書の可否をセット
   *   2. アプリ＆Connection情報の取得
   *   3. checkAppAuthority で権限チェック
   */
  useEffect(() => {
    ;(async () => {
      try {
        // 取得開始 → ローディング表示
        setLoading(true)

        // --- 1. サブドメイン＆チーム情報の取得 ---
        const subdomain = getSubdomain(window.location.host)
        if (subdomain === '') {
          // サブドメインが空の場合はトップへ飛ばす
          navigate('/', { replace: true })
          return
        }

        const { data: teamData, status: teamStatus } = await getTeam(subdomain)
        if (teamStatus !== 200) {
          // チーム取得に失敗 → 404
          navigate('/404', { replace: true })
          return
        }

        // --- 2. アプリ＆Connection情報を取得 ---
        //    ( session_token, app_id をパラメータで渡す )
        const { data: appResponse } = await axios.get<GetAppResponse>(
          `${fullUrl()}/apps/${appId}`,
          {
            params: {
              session_token: sessionToken,
              app_id: appId ?? undefined,
            },
          },
        )

        // アプリ情報を state 化
        setApp(appResponse.app)
        // 接続情報を state 化
        setConnection(appResponse.connection)

        // --- 3. 権限チェック ---
        const authorityResponse = await checkAppAuthority(
          {
            app_id: appId ?? undefined,
            session_token: sessionToken,
            app_key: appResponse.app?.client_key,
          },
          teamData.team.device_certificate,
        )

        if (authorityResponse.data.authorized) {
          // 権限あり
          setAccessAllowed(true)
        } else {
          // 理由に応じてメッセージを分けてアプリ一覧へ飛ばす
          if (authorityResponse.data.reason === '$app_access_rule_match') {
            navigateToAppsByFail(
              '管理者が設定したルールによってログインが失敗しました',
              'appAccessRuleDenySnackbar',
            )
            return
          } else if (authorityResponse.data.reason === '$device_is_locked') {
            navigateToAppsByFail(
              '端末が凍結されているためアプリへアクセスできませんでした',
            )
            return
          } else if (
            authorityResponse.data.reason ===
            '$user_is_not_authorized_access_to_app'
          ) {
            navigateToAppsByFail('アプリへのアクセス権限がありません')
            return
          } else {
            navigateToAppsByFail('アプリへアクセスできませんでした')
            return
          }
        }
      } catch (error) {
        navigateToAppsByFail('アプリへアクセスできませんでした')
        return
      } finally {
        // 正常時／エラー時ともにロード終了
        setLoading(false)
      }
    })()
  }, [appId, sessionToken])

  // ---- ローディング中の表示 ----
  if (loading) {
    return (
      <main
        data-test="loadingTransferPage"
        className="pb-20 pt-10 w-full flex justify-center"
      >
        <Loading />
      </main>
    )
  } else if (!loading && typeof app === 'undefined') {
    navigateToAppsByFail('ログインに失敗しました')
    return <div></div>
  }

  /**
   * 実際に各種アプリへ遷移させるためのコンポーネント
   * conn_type により転送先（Ebase, SAML, Form Based, Bookmark, etc...）を分岐する
   */
  const AppTransfer = () => {
    // アプリ情報/接続情報が存在 & 権限チェックに成功した場合
    if (app && connection && accessAllowed) {
      if (connection.conn_type === 'ebase') {
        return (
          <EbaseTransfer
            app={app}
            connection={connection}
            acId={acId ?? undefined}
          ></EbaseTransfer>
        )
      } else if (connection.conn_type === 'form_based_auth') {
        if (typeof acId === 'string') {
          return (
            <FormBasedAuthTransfer
              app={app}
              connection={connection}
              acId={acId}
            ></FormBasedAuthTransfer>
          )
        } else {
          // ここに入ったらバグ
          navigate('/apps', {
            state: {
              snackbarText:
                'アプリのログイン情報の設定が不足しています。LOCKEDサポート担当までご連絡ください。',
            },
            replace: true,
          })
          return <></>
        }
      } else if (connection.conn_type === 'bookmark') {
        return <BookmarkTransfer app={app} connection={connection} />
      } else if (connection.conn_type === 'saml') {
        return (
          <SamlTransfer
            app={app}
            connection={connection}
            acId={acId ?? undefined}
            samlRequest={navigateState?.samlRequest}
            relayState={navigateState?.relayState}
          ></SamlTransfer>
        )
      }
    }

    return <div></div>
  }

  return (
    <>
      <AppTransfer></AppTransfer>
    </>
  )
}
