import { AxiosResponse } from 'axios'
import { fullUrl } from 'scripts/fullUrl'
import { checkAppAuthorityResponse } from 'types/ApiResponse'
import { checkAppAuthorityRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const checkAppAuthority = async (
  params: checkAppAuthorityRequest,
  device_certificate: boolean,
): Promise<AxiosResponse<checkAppAuthorityResponse>> => {
  // 手違いで文字列の"true"/"false"が入ってしまうのが怖いので、念の為明示的にbooleanで比較

  let endpoint

  if (device_certificate === true) {
    // 1. まず id_ping_for_certificate にサブドメインとメールアドレスを送信
    const pingResponse = await axiosClient.post<{
      require_certificate: boolean
    }>(`${fullUrl('api')}/users/id_ping_for_certificate`, {
      session_token: params.session_token,
    })

    // 2. レスポンスが require_certificate === true のときだけエンドポイントを api-certificate に差し替える
    if (pingResponse.data.require_certificate === true) {
      endpoint = `${fullUrl('api-certificate')}/app/authorize`
      return await axiosClient.post<checkAppAuthorityResponse>(endpoint, {
        ...params, // flatなparamsをAPIが要求しているので注意
      })
    }
  }

  // NOTE: もともと使っていたlocked-auth経由し、locked-apiを叩くためのエンドポイントになっているので注意
  endpoint = `${fullUrl()}/apps/${params.app_id}/authorize`
  return await axiosClient.get<checkAppAuthorityResponse>( // api-certificateとは異なり、GETなので注意
    `${fullUrl()}/apps/${params.app_id}/authorize`,
    {
      params,
    },
  )
}
